.menu-list{
  display: inline-block;
    position: relative;
    right: 4%;
    font-weight: 400;
    font-size: 20px;
    color: rgb(1, 0, 0);
    font-family: sans-serif;
    text-decoration: none;
    justify-content: space-around;
    padding: 0 20px;
  }

.menu-list:hover  {
    color: rgb(177, 117, 117);
  }

  .menu-list:active  {
    color: bisque;
  }


  .App-header {
    background-color: #ffc234;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 5px 0px;
    box-shadow: #222324;
    position: fixed;
    top: 0%;
    width: 100%;
    z-index: 10;
    box-shadow: 0 -6px 20px 10px rgba(0,0,0,0.5);
  }

  .company-name {
    /* background-color: #ffffff; */
    border-radius: 20px;
    justify-content: left;
    align-items: center;
    display: flex;
    position: absolute;
    float: left;
    left: 0%;
    color: #0d0023;
    font-family: sans-serif;
    padding: 5px;
  }

  .logo{
    width: 8%;
    height: 8%;
  }

  .hamburger{
    left: 85%;
    top: 30%;
    display: none;
  }

  .hamburger-button{
    padding: 3px;
    border: 1px inset;
    border-radius: 10px;
  }

  .menu-list-mobile{
      display: none;
      position: relative;
      right: 4%;
      font-weight: 400;
      font-size: 20px;
      color: rgb(1, 0, 0);
      font-family: sans-serif;
      text-decoration: none;
      justify-content: space-around;
      padding: 0 20px;
      align-items: center;
    justify-content: center;
    }

  @media screen and (max-width: 800px) {
    .main{
      margin-top: 7rem;
    }
    .App-header{
      display: block;
      width: 100%;
      padding: 0%;
      position: fixed;
      top: 0%;
    }

    .menu-list{
      top: 6rem;
      display: none;
      background-color: wheat;
      margin-left: 50px;
    }
    .company-name {
      /* background-color: #ffffff; */
      display: flex;
      position: absolute;
      left: 0%;
      color: #0d0023;
      font-size: 10px;
      font-family: sans-serif;
      padding: 5px;
      /* background-color: #222324; */
    }

    .logo{
      width: 15%;
      height: 15%;
    }

    .hamburger{
      left: 85%;
      top: 30%;
      display: block;
      position: absolute;
    }

    .menu-list-mobile{
      display: flex;
      position: relative;
      top: 5rem;
      /* right: 4%; */
      font-weight: 400;
      font-size: 20px;
      color: rgb(1, 0, 0);
      font-family: sans-serif;
      text-decoration: none;
      /* justify-content: space-around; */
      padding: 10px;
      align-items: center;
      justify-content: center;
      width: 100%;
      z-index: 1;
      background-color: #a78261ea;
    }
  }
