.heading{
    /* padding: 0px 40px; */
    font-family: sans-serif;
}

table{
    border: none;
    width: fit-content;
}

td{
    border: none;
}

.dailySevas{
    display: block;
    float: left;
    width: 40%;
}

.paymentsInfo{
    float: right;
}

.dataArea{
    padding-bottom: 4rem;
}


@media screen and (max-width: 800px) {
 .dailySevas{
    clear: both;
    width: 100%;
 }
 .paymentsInfo{
    clear: both;
    padding-bottom: 5rem;
 }
}