.layout {
    position: absolute;
    float: right;
    top: 15%;
    background-image: url(../../Assets/logo.png);
    background-color: transparent;
    background-size: contain;
    width: 80%;
    background-repeat: no-repeat;
    height: 35rem;
    right: 0%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    opacity: 0.3;

}

.content-area {
    position: absolute;
    width: 60%;
    font-size: 18px;
    font-family: sans-serif;
    align-items: center;
    justify-content: center;
    float: left;
    padding-bottom: 5rem;
    margin: 0;
}

@media screen and (max-width: 800px) {
    .content-area {
        width: 100%;
    }

    .layout {
        width: 100%;
        top: 8rem;
    }
}