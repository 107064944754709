.img-list{
    overflow: hidden;
    /* background-color: aquamarine; */
    /* padding: 50px; */
}

.img-items-style{
 padding: 5px;
 overflow: hidden;
}

.img-style{
    border: 5px solid rgb(255, 255, 255);
    border-radius: 10px;
   }

.heading{
    /* padding: 0px 40px; */
    font-family: sans-serif;
}

.gallery-container{
    padding: 0px 10px;
}