.heading{
    /* padding: 0px 40px; */
    font-family: sans-serif;
}

.content{
    position: relative;
    float: right;
    margin-left: 30px;
    background-color: antiquewhite;
    width: 100%;
    height: fit-content;
    margin-bottom: 5rem;
    font-family: sans-serif;
}
.timings{
    clear: both;
    position: relative;
    float: right; 
    right: 2%;
    background-color: antiquewhite;
    width: 40%; 
    padding: 20px;
    font-family: sans-serif;
    
}

 .box-area{
    display: block;
    width: 100%;
} 

.maps{
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: 30px;
    /* background-color: blue; */
}

table{
    width: 100%;
    border: 1px solid brown;
}

td{
    padding: 5px;
    border: 1px solid brown;
}

@media screen and (max-width: 800px) {

}